import AirDatepicker from 'air-datepicker';
import localEn from 'air-datepicker/locale/en';

import 'air-datepicker/air-datepicker.css';
import './airDatepicker.scss';

window.createAirDatepicker = function (el, options) {
    const defaultOptions = {
        firstDay: 1,  // Monday
        dateFormat: 'dd/MM/yyyy',
        locale: localEn
    };

    return new AirDatepicker(el, {...defaultOptions, ...options});
}
