window.zamzarFormatNews = function (category, headline, body) {
    // Append the close button to the title
    var title = category + '<a href="#" class="close">x</a>';
    var content = '<h4>' + headline + '</h4>' + body;

    return {
        title: title,
        content: content
    };
};

$(document).ready(function () {

    var registerDismissButtons = function () {

        var notifyServerThatNewsHasBeenRead = function () {
            var newsLink = $('button#news');
            var url = newsLink.data("mark-as-read-url");
            var storyID = newsLink.data("story-id");
            var csrfToken = $("meta[name=csrf_token]").attr("content");

            $.ajax({
                url: url,
                type: 'POST',
                data: {
                    _token: csrfToken,
                    storyID: storyID
                }
            });
        };

        $(document).on("click", ".popover .close", function () {
            $(this).parents(".popover").prev().popover('hide');
            notifyServerThatNewsHasBeenRead();
        });

        $(document).on("click", "button#news", function () {
            notifyServerThatNewsHasBeenRead();
        });
    };

    var registerPopoverRepositioner = function () {
        var repositionPopover = function () {
            var popover = $(".popover");

            if (popover.length) {
                // Find the element that the popover should be "attached to"
                var anchor = $("button#news");

                // Don't adjust the Y position
                var newTop = popover.offset().top;

                // Adjust the X position such that the popover is centered under the anchor
                var newLeft = anchor.offset().left + (anchor.width() / 2) - (popover.width() / 2);

                popover.offset({top: newTop, left: newLeft});
            }
        };

        // A timer used to ensure that we don't overwhelm the browser with calls to repositionPopover
        var refreshThrottler;

        $(window).resize(function () {
            if (refreshThrottler) {
                clearTimeout(refreshThrottler);
                refreshThrottler = setTimeout(repositionPopover, 25); // wait 25ms before repositioning
            } else {
                repositionPopover();
            }
        });
    };

    var setNews = function (title, content, show, markAsReadUrl, storyID) {
        var newsLink = $('button#news');

        newsLink.popover('destroy');

        newsLink.popover({
            placement: 'bottom',
            title: title,
            content: content,
            html: true
        });

        newsLink.data("mark-as-read-url", markAsReadUrl);
        newsLink.data("story-id", storyID);

        if (show)
            newsLink.popover('show');
    };

    var setNewsToLoadingPlaceholder = function () {
        var title = '<i class="fa fa-circle-o-notch fa-spin spinner" style="float:left"></i> Loading';
        var content = 'We\'ll have the latest news for you in just a second.';
        setNews(title, content);
    };

    var setNewsToNoNewsPlaceholder = function () {
        var title = 'Nothing to report';
        var content = 'We don\'t have any news to share with you at the moment. Have a great day!';
        setNews(title, content);
    };

    var updateNewsFromServer = function () {
        $.ajax({
            url: 'https://developers.zamzar.com/rest/news',
            type: 'GET',
            contentType: 'application/json',
            success: function (data, textStatus, jqXHR) {
                if (typeof data === 'string') {
                    try {
                        data = $.parseJSON(data);
                    } catch (ex) {
                    }
                }

                if ($.isEmptyObject(data))
                    setNewsToNoNewsPlaceholder();
                else {
                    var formattedNews = window.zamzarFormatNews(data.category, data.headline, data.body);
                    setNews(formattedNews.title, formattedNews.content, data.popup, data.markAsReadUrl, data.storyID);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                var data = null;
                try {
                    data = $.parseJSON(jqXHR.responseText);
                } catch (ex) {
                    data = {errors: [{message: 'A server error has occurred'}]};
                }

                setNewsToLoadingPlaceholder();
            }
        });
    };

    var MILLISECONDS_IN_A_MINUTE = 1000 * 60;

    registerDismissButtons();
    registerPopoverRepositioner();
    setNewsToLoadingPlaceholder();
    updateNewsFromServer();
    setInterval(updateNewsFromServer, 5 * MILLISECONDS_IN_A_MINUTE);
});
